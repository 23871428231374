import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Alert, AlertTitle, IconButton, InputAdornment} from "@mui/material";
import axios from "axios";
import {validator} from "../utils/validator";
import Copyright from "./Copyright.jsx";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

const theme = createTheme();

export default function SignInSide() {
    const [apiError, setApiError] = useState({
        failed: false,
        message: ''
    });
    const [isDirty, setIsDirty] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setApiError({
            failed: false,
            message: ''
        });
        if (data.get("username") === '' || data.get("password") === '') {
            setErrors(loginValidator({
                username: data.get("username"),
                password: data.get("password")
            }));
            return;
        }
        axios.post('/login', {
                username: data.get("username"),
                password: data.get("password")
            },
            {withCredentials: true}
        ).then(function (resp) {
            if (resp.status !== 200) {
                setApiError({
                    failed: true,
                    message: "Invalid credentials"
                });
                return
            }
            localStorage.setItem('access_token', resp.data.token);
            window.location.reload();
        }).catch(function (error) {
            if (error.response) {
                setApiError({
                    failed: true,
                    message: error.response.data
                });
            } else {
                setApiError({
                    failed: true,
                    message: error.message
                });
            }
        });
    };

    const loginValidator = (formData) => {
        const errors = [];
        if (!formData.username.length) errors.push('username');
        if (!formData.password.length) errors.push('password');
        return errors;
    };

    const hasErrorUsername = errors.includes('username');
    const hasErrorPassword = errors.includes('password');

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/background-image.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/*<img src={"cm-logo.png"} alt="cm-logo"/>*/}
                        <h1>Inloggen</h1>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 5}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Gebruikersnaam/E-Mail"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                placeholder="Voer uw gebruikersnaam of e-mail in"
                                error={hasErrorUsername}
                                helperText={hasErrorUsername && 'Username is required'}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (!isDirty.includes('username')) setIsDirty(['username', ...isDirty]);
                                    setErrors(validator(errors, 'username', value.length !== 0));
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Wachtwoord"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>)
                                }}

                                id="password"
                                placeholder="Voer uw wachtwoord in"
                                autoComplete="current-password"
                                error={hasErrorPassword}
                                helperText={hasErrorPassword && 'Password is required'}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (!isDirty.includes('password')) setIsDirty(['password', ...isDirty]);
                                    setErrors(validator(errors, 'password', value.length !== 0));
                                }}
                            />

                            {(apiError && apiError.failed) &&
                                <Alert hidden={!apiError.failed} severity="error" sx={{marginTop: '15px'}}>
                                    <AlertTitle sx={{textAlign: 'left'}}>Error</AlertTitle>
                                    {apiError.message}
                                </Alert>
                            }

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Inloggen
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="body2">Wachtwoord vergeten? Stuur een e-mail naar <a
                                        href="mailto:vvedenieuwepoort@gmail.com"> vvedenieuwepoort@gmail.com</a></Typography>
                                    {/*<Link href="/forgotpassword/" variant="body2">*/}
                                    {/*    Forgot password?*/}
                                    {/*</Link>*/}
                                </Grid>

                            </Grid>

                            <Copyright sx={{mt: 5}}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
