export const validator = (errors, errorName, validated) => {
    const hasError = errors.includes(errorName);
    if (validated) {
        // remove the error from the array now that it is no longer an error.
        if (hasError) {
            // Filter returns a new array by default.
            return errors.filter((e) => e !== errorName);
        }
        return errors;
    }

    // If it wasn't an error before, it is now. So we add it to the errors array.
    if (!hasError) {
        // Still want to avoid mutating the errors array directly since it a reference to the state value, so we return a copy.
        return [...errors, errorName];
    }
    return errors;
};

export const checkIfValidUUID = (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
};
