import './App.css';
import React from "react";

import Login from "./components/Login.jsx";
import Register from "./components/Register";
import ForgotPasswordSide from "./components/ForgotPassword";

function App() {
    const isRegistrationPage = window.location.pathname.includes("/register/");
    const isForgotPasswordPage = window.location.pathname.includes("/forgotpassword/");
    const isLoginPage = !isRegistrationPage && !isForgotPasswordPage;

    return (
        <div className="App">
            <header className="App-header">
                <div style={{width: '100%'}}>
                    {(isLoginPage) && <Login/>}
                    {(isRegistrationPage) && <Register/>}
                    {(isForgotPasswordPage) && <ForgotPasswordSide/>}
                </div>
            </header>
        </div>
    );
}

export default App;
