import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Alert, AlertTitle, IconButton, InputAdornment} from "@mui/material";
import axios from "axios";
import {validator} from "../utils/validator";
import Copyright from "./Copyright.jsx";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Cookies from 'js-cookie';

const theme = createTheme();

export default function SignUpSide() {
    const [user] = useState(Cookies.get('VVE-NieuwPoort-User-Details'));
    const [apiError, setApiError] = useState({
        failed: false,
        message: ''
    });
    const [isDirty, setIsDirty] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showFirstPassword, setShowFirstPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);

    const handleClickShowFirstPassword = () => setShowFirstPassword((show) => !show);
    const handleClickShowSecondPassword = () => setShowSecondPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setApiError({
            failed: false,
            message: ''
        });

        if ((data.get("passwordFirst") === '' || data.get("passwordSecond") === '') || (data.get("passwordFirst") !== data.get("passwordSecond"))) {
            setErrors(registerValidator({
                passwordFirst: data.get("passwordFirst"),
                passwordSecond: data.get("passwordSecond")
            }));
            return;
        }
        setErrors([]);
        axios.post(window.location.href, {
            password: data.get("passwordFirst")
        }, {
            withCredentials: true
        }).then(function (resp) {
            if (resp.status !== 200) {
                setApiError({
                    failed: true,
                    message: "Failed to register"
                });
                return
            }
            window.location.href = "/";
        }).catch(function (error) {
            setApiError({
                failed: true,
                message: error.message
            });
        });
    };

    const registerValidator = (formData) => {
        const errors = [];
        if (!formData.passwordFirst.length) errors.push('passwordFirst');
        if (formData.passwordFirst !== formData.passwordSecond) errors.push('passwordMismatch');

        return errors;
    };

    const hasErrorPasswordFirst = errors.includes('passwordFirst');
    const hasErrorPasswordMismatch = errors.includes('passwordMismatch');

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/background-image.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <h1>Registreren</h1>
                        <h6>Wachtwoord configuratie voor: <u><i>{user}</i></u></h6>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 5}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="passwordFirst"
                                label="Nieuw Wachtwoord"
                                type={showFirstPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowFirstPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showFirstPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>)
                                }}
                                name="passwordFirst"
                                autoComplete="password"
                                autoFocus
                                placeholder="Voer een wachtwoord op"
                                error={hasErrorPasswordFirst}
                                helperText={hasErrorPasswordFirst && 'Voer een wachtwoord in'}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (!isDirty.includes('passwordFirst')) setIsDirty(['passwordFirst', ...isDirty]);
                                    setErrors(validator(errors, 'passwordFirst', value.length !== 0));
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="passwordSecond"
                                label="Wachtwoord Opnieuw"
                                type={showSecondPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowSecondPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showSecondPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>)
                                }}

                                id="passwordSecond"
                                placeholder="Voer het wachtwoord opnieuw op"
                                error={hasErrorPasswordMismatch}
                                helperText={hasErrorPasswordMismatch && 'Wachtwoord komt niet overeen'}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (!isDirty.includes('passwordSecond')) setIsDirty(['passwordSecond', ...isDirty]);
                                    setErrors(validator(errors, 'passwordSecond', value.length !== 0));
                                }}
                            />

                            {(apiError && apiError.failed) &&
                                <Alert hidden={!apiError.failed} severity="error" sx={{marginTop: '15px'}}>
                                    <AlertTitle sx={{textAlign: 'left'}}>Error</AlertTitle>
                                    {apiError.message}
                                </Alert>
                            }

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Bevestigen
                            </Button>
                            <Copyright sx={{mt: 5}}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
